import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import ScrollableListViewPPG from "./ScrollableListViewPPG";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { dga } from "_services/dgaAPITest";
import { getCurrency } from "components/Navbar/NavUserBox";
import { BaseServiceURLs } from "BaseServiceURLs";
import ScrollableListView, { GameProviders } from "./ScrollableListView";

const LiveCasino = (props) => {
  const {
    user,
    games,
    allGames,
    loggedIn,
    liveGames,
    UpdateLiveGame,
    UpdateLiveGameProviders,
  } = props;
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [FilterSearchValue, setFilterSearchValue] = useState("");
  const [currentProvider, setCurrentProvider] = useState("");
  const [updateList, setUpdateList] = useState("");

  let params = GetParams();
  const navigate = useNavigate();

  function GetParams() {
    let params = useParams();
    return params;
  }

  let casinoId = BaseServiceURLs.PRAGMATIC_CASINO_ID
    ? BaseServiceURLs.PRAGMATIC_CASINO_ID
    : "ppcdx00000014043";
  let url = BaseServiceURLs.PRAGMATIC_CASINO_LIVE
    ? BaseServiceURLs.PRAGMATIC_CASINO_LIVE
    : "prelive-dga0.pragmaticplaylive.net"; //"dga.pragmaticplaylive.net";

  useEffect(() => {
    if (user) {
      dga.connect(url);
      dga.onMessage = onMessageWebsocket;
      dga.onConnect = () => dga.available(casinoId);
      const _dga = dga;
    }
    return () => {
      dga.disconnect();
    };
  }, []);

  useEffect(() => {
    setFilterSearchValue("");
    if (params.ProviderId) {
      setCurrentProvider(params.ProviderId);
      setUpdateList(params.ProviderId);
    }
  }, [params.ProviderId]);

  useEffect(() => {
    if (games && params && params.ProviderId) {
      if (games.hasOwnProperty(params.ProviderId)) {
        setCurrentProvider(params.ProviderId);
        //setUpdateList(params.ProviderId);
      }
    }
    /*
    try {
      if (games) {
        const _allGames = allGames;
       // const aa = getFilterTimelessTech("live-casino");
        debugger;
      }
    } catch (error) {}

    */
  }, [games, params.ProviderId]);

  const getFilterTimelessTech = (subtype, type) => {
    return Object.keys(games).filter((x) => {
      if (x.includes("timelesstech")) {
        return games[x].length > 0
          ? subtype
            ? games[x][0].subtype == subtype
            : true && subtype
            ? games[x][0].type == type
            : true
          : false;
      }
      return x.includes("timelesstech");
    });
  };
  useEffect(() => {
    window.addEventListener("beforeunload", closeConnections);

    return () => {
      window.removeEventListener("beforeunload", closeConnections);
      closeConnections();
    };
  }, []);

  const closeConnections = () => {
    dga.disconnect();
  };

  // sockets state değiştiğinde useEffect çalışır

  function onMessageWebsocket(data) {
    if (data.hasOwnProperty("tableKey") && Object.keys(data).length == 1) {
      data["tableKey"].forEach((x) => subscribeKey(x));
    } else if (data.hasOwnProperty("tableName")) {
      processTable(data);
      //   document.querySelector(".loader-container").style.display = "none";
    }
  }
  const processTable = (data) => {
    // İlgili id'ye sahip elementi bulma
    var limitdiv = document.getElementById("ppg-limitbet" + data.tableId);
    var accountdiv = document.getElementById("ppg-account" + data.tableId);
    //  var poster = document.getElementById("ppg-poster" + data.tableId);

    if (accountdiv) {
      // Element varsa içeriğini güncelle
      accountdiv.innerHTML =
        "<div class='flex flex-row items-center'>" +
        "<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-2 h-2'> <path fill-rule='evenodd' d='M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z' clip-rule='evenodd' /> </svg>" +
        (data.availableSeats
          ? `${data.totalSeatedPlayers}/${Math.abs(
              data.totalSeatedPlayers - data.availableSeats
            )}`
          : data.totalSeatedPlayers) +
        "</div>";
      accountdiv.style.opacity = 1;
    }
    if (limitdiv) {
      if (data.hasOwnProperty("tableLimits")) {
        // Element varsa içeriğini güncelle
        const cur = getCurrency(data.currency);
        const min = data.tableLimits.minBet + cur; //.replace("IQD", "TND");
        const max = data.tableLimits.maxBet + cur; //.replace("IQD", "TND");
        limitdiv.innerHTML = "<span>" + `${min} - ${max}` + "</span>";
        limitdiv.style.opacity = 1;
      } else {
      }
    }
    /*  if (poster && data.tableImage) {
      poster.src = data.tableImage;
    }
 
  */
  };
  function subscribeKey(key) {
    if (key != 1301 && key != 1320) {
      dga.subscribe(
        casinoId,
        key,
        user?.Currency == "IQD" ? "TND" : user?.Currency
      );
    }
  }
  useEffect(() => {
    getGameProviders();

    const providersList = [
      {
        name: "live/537.png",
        value: "537",
      },
      {
        name: "live/668.png",
        value: "668",
      },
      {
        name: "live/480.png",
        value: "791",
      },
      {
        name: "live/19.png",
        value: "19",
      },
      ...[
        ...GameProviders.filter((x) => {
          if (
            x.value == "668" &&
            !props?.permissions?.DETROIT_ABSOLUTE_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "791" &&
            !props?.permissions?.DETROIT_EVOLUTION_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "19" &&
            !props?.permissions?.DETROIT_EZUGI_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "WorldMatch" &&
            !props?.permissions?.WM?.Value
          ) {
            return false;
          } else if (
            x.value == "WorldMatch" &&
            !props?.permissions?.WM?.Value
          ) {
            return false;
          } else if (
            x.value == "pragmatic-casino" &&
            !props?.permissions?.PRAGMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "amatic" &&
            !props?.permissions?.AMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "novomatic" &&
            !props?.permissions?.NOVOMATIC_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "bomba" &&
            !props?.permissions?.BOMBA_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "egt" &&
            !props?.permissions?.EGT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "netent" &&
            !props?.permissions?.NETENT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "yggdrasil" &&
            !props?.permissions?.YGGDRASIL_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "playngo" &&
            !props?.permissions?.PLAYNGO_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "aristocrat" &&
            !props?.permissions?.ARISTOCRAT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "redtiger" &&
            !props?.permissions?.REDTIGER_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "playtech" &&
            !props?.permissions?.PLAYTECH_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "1X2_GAMING" &&
            !props?.permissions?.X12_GAMING_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "EVOPLAY" &&
            !props?.permissions?.EVOPLAY_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "IGT" &&
            !props?.permissions?.IGT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "PLAYSON" &&
            !props?.permissions?.PLAYSON_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "BOOONGO" &&
            !props?.permissions?.BOOONGO_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "WAZDAN" &&
            !props?.permissions?.WAZDAN_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "RUBYPLAY" &&
            !props?.permissions?.RUBYPLAY_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "MICROGAMING" &&
            !props?.permissions?.MICROGAMING_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "BETSOFT" &&
            !props?.permissions?.BETSOFT_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "OCTAVIAN" &&
            !props?.permissions?.OCTAVIAN_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "SCIENTIFIC_GAMES" &&
            !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Evolution-fundist" &&
            !props?.permissions?.WM_EVOLUTION_CASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Ezugi-fundist" &&
            !props?.permissions?.WM_EZUGI_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "Atmosfera-fundist" &&
            !props?.permissions?.WM_ATMOSFERA_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "VivoGaming-fundist" &&
            !props?.permissions?.WM_VIVO_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "WMCasino-fundist" &&
            !props?.permissions?.WM_WMCASINO_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "XProGaming-fundist" &&
            !props?.permissions?.WM_XPROGAMING_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "LuckyStreak-fundist" &&
            !props?.permissions?.WM_LUCKYSTREAK_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "AESexyBaccarat-fundist" &&
            !props?.permissions?.WM_AESEXY_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else if (
            x.value == "LiveGames-fundist" &&
            !props?.permissions?.WM_LIVEGAMES_LIVECASINO_ACCESS?.Value
          ) {
            return false;
          } else return true;
        }),
      ].filter((x) => games && games.hasOwnProperty(x.value)),
    ].filter((x) => {
      //debugger;
      if (
        x.value == "537" &&
        !props?.permissions?.PRAGMATIC_LIVECASINO_ACCESS?.Value
      ) {
        return false;
      } else if (
        x.value == "668" &&
        !props?.permissions?.DETROIT_ABSOLUTE_LIVECASINO_ACCESS?.Value
      ) {
        return false;
      } else if (
        x.value == "791" &&
        !props?.permissions?.DETROIT_EVOLUTION_LIVECASINO_ACCESS?.Value
      ) {
        return false;
      } else if (
        x.value == "19" &&
        !props?.permissions?.DETROIT_EZUGI_LIVECASINO_ACCESS?.Value
      ) {
        return false;
      } else {
        return true;
      }
    });

    if (providersList.length > 0) {
      navigate("/live-casino/" + providersList[0].value);
    }
  }, []);

  useEffect(() => {
    // getGames(params.ProviderId);
    if (
      params?.ProviderId == "668" ||
      params?.ProviderId == "791" ||
      params?.ProviderId == "19"
    )
      getGames(params.ProviderId);

    setFilterSearchValue("");
  }, [params.ProviderId]);

  useEffect(() => {
    if (
      params?.ProviderId == "668" ||
      params?.ProviderId == "791" ||
      params?.ProviderId == "19"
    )
      getGames(params.ProviderId);
  }, []);

  const getGameProviders = async () => {
    setLoading(true);
    await GameService.GET_LIVE_GAME_PROVIDERS()
      .then((res) => {
        setLoading(false);
        UpdateLiveGameProviders(res);
        //UpdateGames(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getGames = async () => {
    setLoading(true);
    await GameService.GET_LIVE_GAMES(params?.ProviderId)
      .then((res) => {
        setLoading(false);
        UpdateLiveGame(res);
        debugger;
        setUpdateList(params.ProviderId + (res.length ? res.length : "-"));
        //UpdateGames(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  let isFetching = false;

  const getGamesMore = async () => {
    setLoading(true);
    if (!isFetching) {
      isFetching = true;
      await GameService.GET_LIVE_GAMES(params?.ProviderId, null, page + 1)
        .then((res) => {
          setPage(page + 1);

          const idSet = new Set(liveGames.map((item) => item._id));
          const uniqueNewArray = res.filter((item) => !idSet.has(item._id));

          UpdateLiveGame([...liveGames, ...uniqueNewArray]);
          setLoading(false);

          //UpdateGames(res);
        })
        .catch((err) => {
          setLoading(false);
        });
      setTimeout(() => {
        isFetching = false;
      }, 500);
    }
  };

  const pb = require("../../../assets/img/banners/lc2.png");
  const pragmaticNotCasino = ["1301", "1302", "1320"];

  return (
    <Translation>
      {(t) => (
        <>
          <Helmet>
            <title>{t("TITLE_LIVECASINO")}</title>
          </Helmet>
          <div
            key={
              updateList == "537"
                ? props.games
                  ? "pragmatic"
                  : "573"
                : parseInt(updateList)
                ? props.liveGames.length > 0
                  ? "dc-" + updateList
                  : updateList
                : updateList && updateList?.includes("-fundist")
                ? props.games
                  ? updateList + "list"
                  : updateList
                : updateList
            }
            className={"xxx " + updateList}
          >
            {params?.ProviderId == "537" ? (
              <ScrollableListView
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                pb={pb}
                isDirectLobby={false}
                title={t("TITLE_LIVECASINO")}
                provider={currentProvider}
                setProvider={setCurrentProvider}
                data={
                  props?.games
                    ? props?.games.hasOwnProperty("pragmatic-casino")
                      ? props?.games["pragmatic-casino"]
                          .filter((x) => x.gameTypeID == "lg")
                          .filter(
                            (x) =>
                              !pragmaticNotCasino.find((y) => y == x.gameID)
                          )
                          .filter((x) =>
                            x.gameName
                              .toLocaleLowerCase()
                              .includes(FilterSearchValue.toLocaleLowerCase())
                          )
                      : []
                    : []
                }
                getMore={getGamesMore}
                loading={loading}
                setPage={setPage}
              />
            ) : params?.ProviderId?.includes("timeless") ||
              params?.ProviderId?.includes("-fundist") ? (
              <ScrollableListView
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
                pb={pb}
                isDirectLobby={false}
                title={t("TITLE_LIVECASINO")}
                provider={currentProvider}
                setProvider={setCurrentProvider}
                data={
                  props?.games
                    ? props?.games.hasOwnProperty(params?.ProviderId)
                      ? props?.games[params?.ProviderId]
                      : []
                    : []
                }
                getMore={getGamesMore}
                loading={loading}
                setPage={setPage}
              />
            ) : (
              <ScrollableListView
                FilterSearchValue={FilterSearchValue}
                title={t("TITLE_LIVECASINO")}
                setFilterSearchValue={(value) => {
                  setFilterSearchValue(value);
                }}
                pb={pb}
                isDirectLobby={true}
                data={
                  liveGames
                    ? [
                        ...liveGames.filter((x) =>
                          x.name
                            .toLocaleLowerCase()
                            .includes(FilterSearchValue.toLocaleLowerCase())
                        ),
                      ].filter((x) => x.providerID == params.ProviderId)
                    : []
                }
                getMore={getGamesMore}
                loading={loading}
                setPage={setPage}
              />
            )}
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions, permissionsAll } = authentication;
  const { liveGames, games } = game;
  return {
    user,
    loggedIn,
    liveGames,
    games,
    permissions,
    permissionsAll,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateLiveGame: (data) => dispatch(gameAction.UpdateLiveGame(data)),
    UpdateLiveGameProviders: (data) =>
      dispatch(gameAction.UpdateLiveGameProviders(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveCasino);
