import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetGamePoster } from "../ScrollableListView";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { useParams } from "react-router-dom";
import i18n from "i18n";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Translation } from "react-i18next";
const PragmaticGameBox = (props) => {
  const containerRef = useRef(null);
  const [H, setH] = useState(null);
  const { item, index, isLive } = props;
   let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const resizeBox = () => {
    if (containerRef&& containerRef.current) {
      const w = containerRef.current?.clientWidth;
      const prgmaticName =
        document.querySelector(".prgmaticName")?.offsetHeight;

      containerRef.current.style.height = `${w + prgmaticName}px`;

      setH(`${w + prgmaticName}px`);
    }
  };
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", resizeBox);
    window.addEventListener("resize", resizeBox);
  }, []);

  return (
    <Translation>
      {(t) => (
        <>
          <div
            ref={containerRef}
            key={item.gameID + item.gameIdNumeric}
            className="GameBox h-[143px] md:h-[220px]  bg-primary-500 md:bg-secondary-500 group/item cursor-pointer md:border md:border-primary-500"
            onLoad={() => resizeBox()}
            style={{
              height: H ? H : undefined,
            }}
          >
            <div className="games-img">
              <AsyncImage
                className="h-full w-full rounded-t-[2.5vw] md:rounded-t-md"
                id={"ppg-poster" + item.gameID}
                src={GetGamePoster("pragmatic-casino", item)}
                alt={item.gameName}
                loader={
                  <div className="skeleton w-full h-full bg-secondary-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                error={
                  <div className="bg-light-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
              />
            </div>
            <div className="game-hover group-hover/item:before:opacity-80"></div>
            {isLive && (
              <div
                style={{ position: "absolute" }}
                id={"ppg" + item.gameID}
                className="top-0 w-full  flex flex-row justify-between"
                //className="games-img "
              >
                                <span
                  id={"ppg-limitbet" + item.gameID}
                  className="game-live-limitbet opacity-[0]  bg-primary-500/80"
                ></span>
                <span
                  id={"ppg-account" + item.gameID}
                  className="game-live-limitbet-account opacity-[0] bg-primary-500/80"
                ></span>

              </div>
            )}
            {isLive == true ? (
              <Link
                to={`/pragmatic/${item.gameID}`}
                className="game-btns   flex-col px-6"
              >
                <IoPlayCircleOutline className="text-primary-500 w-14 h-14" />
              </Link>
            ) : (
              <div className="game-btns   flex-col  ">
                <Link
                  to={`/pragmatic/${item.gameID}`}
                  className="flex flex-row m-1  justify-start md:justify-center items-center"
                >
                  <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                  {t("BUTTON_PLAY")}
                </Link>
                <Link
                  to={`/pragmatic-demo/${item.gameID}`}
                  className="flex flex-row m-1  justify-start md:justify-center items-center"
                >
                  <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                  {t("BUTTON_DEMOPLAY")}
                </Link>
              </div>
            )}

            <span className="prgmaticName name text-white after:bg-secondary-500 group-hover/item:after:bg-primary-500 group-hover/item:after:opacity-[0.9] group-hover/item:text-secondary-500">
              {item.gameName}
            </span>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PragmaticGameBox);
