import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Mousewheel,
} from "swiper/modules";
import "./style.css";
import SwiteGridItem from "./SwiteGridItem";

import "swiper/css";

export default function ProvidersMobileSwiper({ _data ,provider,updateItem }) {
  const [data, setData] = useState([]);
  function preparePagePart(_d, _c) {
    return Array.from({ length: Math.ceil(_d.length / _c) }, (_, index) =>
      _d.slice(index * _c, (index + 1) * _c)
    );
  }
  const _columns =4;
  const _row = 3;
  useEffect(() => {
    const _v = preparePagePart(_data, _columns);
    setData(_v);
    
  }, []);
  return (
    <div className="p-2 h-[180px] flex flex-row items-center bg-light-500 pb-2"
    style={{
      height:
        Math.round(
          _data.length / _columns > _row ?_row : _data.length / _columns
        ) *
          62 +
        "px",
      minHeight: 
      Math.round(
        _data.length / _columns > _row ? _row :_data.length / _columns
      ) *
      "px",
    }}
  >
      <Swiper
        direction={"vertical"}
        slidesPerView={Math.round(
          _data.length / _columns > _row ?_row : _data.length / _columns
        )}
        spaceBetween={10}
        mousewheel={true}
        modules={[Mousewheel]}
        className="mySwiper"
 
      >
        {(data ? data : []).map((item, key) => (
          <SwiperSlide className="" key={key}>
            <SwiteGridItem provider={provider} data={item} columns={_columns}/>
          </SwiperSlide>
        ))}
      </Swiper>
   {
    
   }
    </div>
  );
}
