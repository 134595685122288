import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useRef, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProvidersSwiper from "../Games/ProvidersSwiper";
import PageBanner from "../PageBanner";
import ProvidersMobileSwiper from "../Games/ProvidersMobileSwiper";
import GridContainer from "../Games/GridContainer";
import { MdOutlineCasino } from "react-icons/md";
import { PiCoinsBold } from "react-icons/pi";
import FilterSearch from "../Games/FilterSearch";
import FilterTypes from "../Games/FilterTypes";
import PragmaticGameBox from "../GamerBoxes/PragmaticGameBox";
import SmartSoftGameBox from "../GamerBoxes/SmartSoftGameBox";
import BLAS975GameBox from "../GamerBoxes/BLAS975GameBox";
import { useParams } from "react-router-dom";

const ScrollableListView = (props) => {
  const {
    user,
    loggedIn,
    sports,
    games,
    UpdateSport,
    data,
    pb,
    provider,
    setProvider,
    title,
    children,
  } = props;
  const [loading, setLoading] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(true);

  const [visibleData, setVisibleData] = useState([]);
  const containerRef = useRef(null);

  const [currentProvider, setCurrentProvider] = useState(null);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1);
        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);

  return (
    <Translation>
      {(t) => (
        <div>
          <div
            style={{ overflowY: "scroll" }}
            ref={containerRef}
            // onScroll={handleScroll}
            className="pb-[180px] bg-secondary-500"
            onLoad={adjustHeight}
          >
            <PageBanner
              title={title}
              subTitle={t("SUBTITLE_BANNER_CASINO")}
              bg={pb}
              show={true}
            />

            {/*
              <section className=" py-2 mobile-show ">
              <ProvidersMobileSwiper _data={[...GameProviders]} />
            </section>

            <section className="py-2 mobile-hide">
              <ProvidersSwiper _data={[...GameProviders]} />
            </section>

                 <section className="mb-1">
              <FilterTypes />
            </section>

            */}

            <div className="px-1 pt-4 grid grid-cols-2  sm:grid-cols-4  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6  2xl:grid-cols-8 gap-2 md:gap-4">
              {children}
            </div>
          </div>

          <div className=" mobile-show">
            <div
              style={{ visibility: isNavVisible ? "visible" : "hidden" }}
              className="btm-nav  border-t border-primary-500 bg-gradient-to-r from-primary-700 via-primary-600 to-primary-700 drop-shadow-2xl shadow-2xl"
            >
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/sports"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_SPORTS")}
                    </span>
                  </div>
                </Link>
              )}
              <Link
                to={"/games"}
                className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <MdOutlineCasino className="w-5 h-5" />
                  <span className="btm-nav-label text-xs">
                    {t("TITLE_CASINO")}
                  </span>
                </div>
              </Link>
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/my-account/coupons"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_MYBETS")}
                    </span>
                  </div>
                </Link>
              )}

              {props.permissions?.LIVECASINO_ACCESS?.Value && (
                <Link
                  to={"/live-casino"}
                  className="text-white max-w-[5rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <PiCoinsBold className="w-5 h-5" />
                    <span className="btm-nav-label text-xs">
                      {t("TITLE_LIVECASINO")}
                    </span>
                  </div>
                </Link>
              )}

              <Link
                to={"/my-account/profile/"}
                className="text-white max-w-[5rem] bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>

                  <span className="btm-nav-label text-xs">
                    {t("TITLE_MYACCOUNT")}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions, permissionsAll } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
    permissionsAll,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrollableListView);

export const GetGamePoster = (cat, data) => {
  switch (cat) {
    case "pragmatic-casino":
      return `https://common-static.ppgames.net/game_pic/square/200/${data.gameID}.png`;
    case "smartsoft":
      return `https://maxbetone.s3.eu-central-1.amazonaws.com/images/smartsoft/${data.GameName}.png`.toLowerCase();
    default:
      break;
  }
};
