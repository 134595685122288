import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetGamePoster } from "../ScrollableListView";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Translation } from "react-i18next";

export function oranHesapla(ilkW, ilkH, yeniW) {
  var oran = ilkH / ilkW;
  var yeniH = oran * yeniW;
  return yeniH;
}

const BLAS975GameBox = (props) => {
  const { item, index, provider } = props;
  const containerRef = useRef(null);
  const [H, setH] = useState(null);



  const resizeBox = () => {
    if (containerRef&& containerRef.current) {
      let newW = containerRef.current?.clientWidth;
      const prgmaticName = document.querySelector(".blasName")?.offsetHeight;
      let firstW = 0;
      let firstH = 0;

      switch (item.vendor) {
        case "amatic":
          firstH = 225;
          firstW = 330;
          break;
        case "novomatic":
          firstH = 180;
          firstW = 180;
          break;
        case "bomba":
          firstH = 192;
          firstW = 248;
          break;
        case "egt":
          firstH = 430;
          firstW = 580;
          break;
        case "netent":
          firstH = 240;
          firstW = 360;
          break;
        case "yggdrasil":
          firstH = 405;
          firstW = 720;
          break;
        case "playngo":
          firstH = 28;
          firstW = 23;
          break;
        case "aristocrat":
          firstH = 35;
          firstW = 48;
          break;
        case "redtiger":
          firstH = 180;
          firstW = 180;
          break;
        case "playtech":
          firstH = 169;
          firstW = 240;
          break;

        default:
          break;
      }

      if (firstW) {
        const newH = oranHesapla(firstW, firstH, newW);
        containerRef.current.style.height = `${newH + prgmaticName}px`;
        setH(`${newH + prgmaticName}px`);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", resizeBox);
    window.addEventListener("resize", resizeBox);
  }, []);

  return (
    <Translation>
      {(t) => (
        <>
          <div
            ref={containerRef}
            onLoad={() => resizeBox()}
            style={{
              height: H ? H : undefined,
            }}
            key={item.id_game + item.name + item.vendor}
            className="GameBox h-[120px] md:h-[220px]  bg-primary-500 md:bg-secondary-500 group/item cursor-pointer"
          >
            <div className="games-img">
              <AsyncImage
                className="h-full w-full rounded-t-[2.5vw] md:rounded-t-md"
                src={item.image}
                loader={
                  <div className="skeleton w-full h-full bg-secondary-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                error={
                  <div className="bg-light-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                style={{
                  objectFit: "fill",
                }}
                alt={`
        ${item.display_name}
        
        [${item.GameName}]`}
              />
            </div>

            <div className="game-hover group-hover/item:before:opacity-80"></div>
            <div className="game-btns   flex-col px-2">
              <Link
                to={`/blas975/${provider}/${item.id_game}`}
                className="flex flex-row m-1  justify-start md:justify-center items-center"
              >
                <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                {t("BUTTON_PLAY")}
              </Link>
              {item.mobile == "1" && (
                <Link
                  to={`/blas975-demo/${provider}/${item.id_game}`}
                  className="flex flex-row m-1  justify-start md:justify-center items-center"
                >
                  <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                  {t("BUTTON_DEMOPLAY")}
                </Link>
              )}
            </div>
            <span className="blasName name text-white after:bg-secondary-500 group-hover/item:after:bg-primary-500 group-hover/item:after:opacity-[0.9] group-hover/item:text-secondary-500">
              {item.display_name}
            </span>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BLAS975GameBox);
