import { connect } from "react-redux";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function SwiteMobileItem({ item, key, className, data, setPage, liveGames }) {
  const [toPath, setToPath] = useState(null);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  useEffect(() => {
    if (!toPath && liveGames.length > 0) {
      const _toPath = data ? getPath() : "/live-casino";
      setToPath(_toPath);
    }
  }, [liveGames]);

  useEffect(() => {
    if (!toPath && liveGames.length > 0) {
      const _toPath = data ? getPath() : "/live-casino";
      setToPath(_toPath);
    }
  }, []);

  const getPath = () => {
    if (data.value == "791") {
      return "/live/58257";
    }
    return "/live-casino/" + data.value;
    try {
      switch (data.value) {
        case "19":
          debugger;
          const _ez = liveGames.find((x) => x.providerID == "19");
          if (!_ez) {
            return null;
          }
          return "/live/" + _ez?.ID;
        case "791":
          debugger;
          const _ev = liveGames.find((x) => x.providerID == "791");
          if (!_ev) {
            return null;
          }
          return "/live/" + _ev?.ID;

        case "668":
          debugger;
          const _ab = liveGames.find((x) => x.providerID == "668");
          if (!_ab) {
            return null;
          }
          return "/live/" + _ab?.ID;

        default:
          return "/live-casino/" + data.value;
      }
    } catch (error) {
      return null;
    }

    // standart olarak lobby'ye yönlendirilmesi istendi.
  };

  /*
  
  live/2508 ezugi lobby


  */

  return data ? (
    <Link
      to={toPath}
      //key={key + data.provider}
      className={
        " game-provider-bg bg-primary-500 w-full  shadow-xl relative p-[.08rem]  " +
        className
      }
    >
      <div
        className={`h-[44px] w-full  ${
          params.ProviderId
            ? params.ProviderId == data.value
              ? "bg-secondary-600/50"
              : "bg-secondary-600"
            : data.value == "pragmatic-casino"
            ? "bg-secondary-600/50"
            : "bg-secondary-600"
        } rounded-lg p-1`}
      >
        <div
          className="h-full w-full"
          style={{
            backgroundImage: `url(${item})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </Link>
  ) : (
    <div
      key={key}
      className={
        " game-provider-bg bg-primary-500 w-full  shadow-xl relative p-[.08rem]  " +
        className
      }
    >
      <div className="h-full w-full  bg-secondary-600 rounded-lg p-1">
        <div
          className="h-full w-full"
          style={{
            backgroundImage: `url(${item})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, currentLang } = authentication;
  const { sports, games, liveGames } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    currentLang,
    liveGames,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SwiteMobileItem);
