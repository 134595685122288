import { gameAction } from "_actions";
import { GameService } from "_services";
import React, { useEffect, useRef, useState } from "react";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProvidersSwiper from "../Casino/ProvidersSwiper";
import PageBanner from "../PageBanner";
import ProvidersMobileSwiper from "../Casino/ProvidersMobileSwiper";
import GridContainer from "../Casino/GridContainer";
import { MdOutlineCasino } from "react-icons/md";
import { PiCoinsBold } from "react-icons/pi";
import FilterSearch from "../Casino/FilterSearch";
import FilterTypes from "../Casino/FilterTypes";
import PragmaticGameBox from "../GamerBoxes/PragmaticGameBox";
import SmartSoftGameBox from "../GamerBoxes/SmartSoftGameBox";
import BLAS975GameBox from "../GamerBoxes/BLAS975GameBox";
import { useParams } from "react-router-dom";
import WMGameBox from "../GamerBoxes/WMGameBox";
import DetroitBox from "../GamerBoxes/DetroitBox";
import { isBrowser, isMobile, isTablet, isAndroid } from "react-device-detect";
import TimelessTechGameBox from "../GamerBoxes/TimelessTechGameBox";

const ScrollableListView = (props) => {
  const {
    user,
    loggedIn,
    sports,
    games,
    UpdateSport,
    data,
    pb,
    provider,
    setProvider,
    FilterSearchValue,
    setFilterSearchValue,
    title,
  } = props;
  const [FilterTypeValue, setFilterTypeValue] = useState(null);

  const [FilterTypeList, setFilterTypeList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isNavVisible, setIsNavVisible] = useState(true);

  const [visibleData, setVisibleData] = useState([]);
  const containerRef = useRef(null);

  const [fabActive, setFabActive] = useState(false);

  let params = GetParams();

  function GetParams() {
    let params = useParams();
    return params;
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop > 300 && !fabActive) {
      setFabActive(true);
      // debugger;
    }
    if (scrollTop < 300 && fabActive) {
      setFabActive(false);
      //    debugger;
    }

    if (scrollTop + clientHeight >= scrollHeight) {
      setVisibleData((prevData) => [
        ...prevData,
        ...data?.slice(prevData.length, prevData.length + 40),
      ]); // Load next 10 items, adjust as needed
    }

    if (scrollTop > lastScrollTop) {
      setIsNavVisible(false);
    } else {
      setIsNavVisible(true);
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };
  useEffect(() => {
    const _v = data ? data.slice(0, 40) : [];
    setVisibleData(_v); // Initial rendering, show first 10 items
  }, [data]);

  function farkliGameTypeIDListesi(arrayList) {
    // Set oluşturarak benzersiz gameTypeID'leri saklayacağız
    let uniqueGameTypeIDs = new Set();

    // Dizi içinde dolaşarak her bir gameTypeID'yi set'e ekleyeceğiz
    arrayList.forEach(function (item) {
      // Eğer item içinde gameTypeID varsa set'e ekleyeceğiz
      if (item.hasOwnProperty("gameTypeID")) {
        uniqueGameTypeIDs.add(item.gameTypeID + " - " + item.typeDescription);
      }
    });

    // Set'i diziye dönüştürerek farklı gameTypeID'lerin listesini elde ederiz
    return Array.from(uniqueGameTypeIDs);
  }

  const adjustHeight = () => {
    try {
      const navbarHeight = document.querySelector(".base-nav")?.offsetHeight;
      const navbarHeight1 = document.querySelector(".base-nav1")?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (containerRef && containerRef.current) {
        const h =
          windowHeight -
          (navbarHeight > navbarHeight1 ? navbarHeight : navbarHeight1);
        containerRef.current.style.height = `${h}px`;
      }
    } catch (error) {}
  };

  useEffect(() => {
    window.addEventListener("DOMContentLoaded", adjustHeight);
    window.addEventListener("resize", adjustHeight);
  }, []);

  const updateProviderGamesSort = (array1, array2) => {
    let nameOrder = {};
    array2.forEach((item, index) => {
      nameOrder[item.name] = index;
    });

    // array1'i array2'deki sıralamaya göre yeniden düzenle
    let sortedArray1 = array1.sort((a, b) => {
      // Eğer iki gameID de array2'de varsa sıralama farkına göre düzenle
      if (
        nameOrder.hasOwnProperty(a.gameID) &&
        nameOrder.hasOwnProperty(b.gameID)
      ) {
        return nameOrder[a.gameID] - nameOrder[b.gameID];
      }
      // Eğer a array2'de var ve b yoksa a öne gelsin
      if (nameOrder.hasOwnProperty(a.gameID)) {
        return -1;
      }
      // Eğer b array2'de var ve a yoksa b öne gelsin
      if (nameOrder.hasOwnProperty(b.gameID)) {
        return 1;
      }
      // Her ikisi de array2'de yoksa orijinal sıralamayı koru
      return 0;
    });

    return sortedArray1;
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div
            style={{ overflow: "scroll" }}
            ref={containerRef}
            onScroll={handleScroll}
            className="pb-[20px] bg-secondary-500 overflow-y-scroll"
            onLoad={adjustHeight}
          >
            <PageBanner
              title={title}
              subTitle={t("SUBTITLE_BANNER_CASINO")}
              bg={pb}
            />
            {isMobile || isTablet || isAndroid ? (
              <section className=" py-2 ">
                <ProvidersMobileSwiper
                  provider={provider}
                  _data={[
                    ...GameProviders.filter((x) => {
                      if (
                        x.value == "WorldMatch-fundist" &&
                        !props?.permissions?.WM?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "pragmatic-casino" &&
                        !props?.permissions?.PRAGMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "pragmatic" &&
                        !props?.permissions?.PRAGMATIC_BLAS975_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "amatic" &&
                        !props?.permissions?.AMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "novomatic" &&
                        !props?.permissions?.NOVOMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "bomba" &&
                        !props?.permissions?.BOMBA_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "egt" &&
                        !props?.permissions?.EGT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } 
                      else if (
                        x.value == "egtdgtl" &&
                        !props?.permissions?.EGTDGTL_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      }else if (
                        x.value == "netent" &&
                        !props?.permissions?.NETENT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "yggdrasil" &&
                        !props?.permissions?.YGGDRASIL_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "playngo" &&
                        !props?.permissions?.PLAYNGO_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "aristocrat" &&
                        !props?.permissions?.ARISTOCRAT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "redtiger" &&
                        !props?.permissions?.REDTIGER_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "playtech" &&
                        !props?.permissions?.PLAYTECH_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "1X2_GAMING" &&
                        !props?.permissions?.X12_GAMING_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "EVOPLAY" &&
                        !props?.permissions?.EVOPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "IGT" &&
                        !props?.permissions?.IGT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "PLAYSON" &&
                        !props?.permissions?.PLAYSON_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BOOONGO" &&
                        !props?.permissions?.BOOONGO_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "WAZDAN" &&
                        !props?.permissions?.WAZDAN_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "RUBYPLAY" &&
                        !props?.permissions?.RUBYPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "MICROGAMING" &&
                        !props?.permissions?.MICROGAMING_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BETSOFT" &&
                        !props?.permissions?.BETSOFT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "OCTAVIAN" &&
                        !props?.permissions?.OCTAVIAN_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "SCIENTIFIC_GAMES" &&
                        !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "merkur" &&
                        !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Evolution-fundist" &&
                        !props?.permissions?.WM_EVOLUTION_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Evoplay-fundist" &&
                        !props?.permissions?.WM_EVOPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "SmartSoft-fundist" &&
                        !props?.permissions?.WM_SMARTSOFT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Tom Horn-fundist" &&
                        !props?.permissions?.WM_TOMHORN_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "GameArt-fundist" &&
                        !props?.permissions?.WM_GAMEART_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "AsiaGamingSlots-fundist" &&
                        !props?.permissions?.WM_ASIAGAMINGSLOTS_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BetSoft-fundist" &&
                        !props?.permissions?.WM_BETSOFT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BGaming-fundist" &&
                        !props?.permissions?.WM_BGAMING_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Novomatic-fundist" &&
                        !props?.permissions?.WM_NOVOMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else return true;
                    }),
                  ].filter((x) => games && games.hasOwnProperty(x.value))}
                />
              </section>
            ) : (
              <section className="py-2 mobile-hide">
                <ProvidersSwiper
                  provider={provider}
                  _data={[
                    ...GameProviders.filter((x) => {
                      if (
                        x.value == "WorldMatch-fundist" &&
                        !props?.permissions?.WM?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "pragmatic-casino" &&
                        !props?.permissions?.PRAGMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "pragmatic" &&
                        !props?.permissions?.PRAGMATIC_BLAS975_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "amatic" &&
                        !props?.permissions?.AMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "novomatic" &&
                        !props?.permissions?.NOVOMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "bomba" &&
                        !props?.permissions?.BOMBA_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "egt" &&
                        !props?.permissions?.EGT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      }
                      else if (
                        x.value == "egtdgtl" &&
                        !props?.permissions?.EGTDGTL_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      }
                       else if (
                        x.value == "netent" &&
                        !props?.permissions?.NETENT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "yggdrasil" &&
                        !props?.permissions?.YGGDRASIL_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "playngo" &&
                        !props?.permissions?.PLAYNGO_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "aristocrat" &&
                        !props?.permissions?.ARISTOCRAT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "redtiger" &&
                        !props?.permissions?.REDTIGER_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "playtech" &&
                        !props?.permissions?.PLAYTECH_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "1X2_GAMING" &&
                        !props?.permissions?.X12_GAMING_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "EVOPLAY" &&
                        !props?.permissions?.EVOPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "IGT" &&
                        !props?.permissions?.IGT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "PLAYSON" &&
                        !props?.permissions?.PLAYSON_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BOOONGO" &&
                        !props?.permissions?.BOOONGO_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "WAZDAN" &&
                        !props?.permissions?.WAZDAN_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "RUBYPLAY" &&
                        !props?.permissions?.RUBYPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "MICROGAMING" &&
                        !props?.permissions?.MICROGAMING_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BETSOFT" &&
                        !props?.permissions?.BETSOFT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "OCTAVIAN" &&
                        !props?.permissions?.OCTAVIAN_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "SCIENTIFIC_GAMES" &&
                        !props?.permissions?.SCIENTIFIC_GAMES_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Evolution-fundist" &&
                        !props?.permissions?.WM_EVOLUTION_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Evoplay-fundist" &&
                        !props?.permissions?.WM_EVOPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "SmartSoft-fundist" &&
                        !props?.permissions?.WM_SMARTSOFT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Tom Horn-fundist" &&
                        !props?.permissions?.WM_TOMHORN_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "GameArt-fundist" &&
                        !props?.permissions?.WM_GAMEART_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "AsiaGamingSlots-fundist" &&
                        !props?.permissions?.WM_ASIAGAMINGSLOTS_CASINO_ACCESS
                          ?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BetSoft-fundist" &&
                        !props?.permissions?.WM_BETSOFT_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "BGaming-fundist" &&
                        !props?.permissions?.WM_BGAMING_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Novomatic-fundist" &&
                        !props?.permissions?.WM_NOVOMATIC_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "Onlyplay-fundist" &&
                        !props?.permissions?.WM_ONLYPLAY_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else if (
                        x.value == "3 Oaks Gaming-fundist" &&
                        !props?.permissions?.WM_3OAKS_CASINO_ACCESS?.Value
                      ) {
                        return false;
                      } else return true;
                    }),
                  ].filter((x) => games && games.hasOwnProperty(x.value))}
                />
              </section>
            )}

            <section className="mb-1">
              <FilterTypes
                FilterSearchValue={FilterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
              />
            </section>
            <div className="px-1 grid grid-cols-3   sm:grid-cols-4  md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7  2xl:grid-cols-8 gap-2 md:gap-4">
              {visibleData &&
                visibleData.map((item, index) => {
                  switch (provider) {
                    case "pragmatic-casino":
                      return (
                        <PragmaticGameBox
                          key={index}
                          isLive={false}
                          index={index}
                          item={item}
                        />
                      );
                    case "pragmatic":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "smartsoft":
                      return (
                        <SmartSoftGameBox
                          key={index}
                          index={index}
                          item={item}
                        />
                      );
                    case "WorldMatch":
                      return (
                        <WMGameBox
                          key={index}
                          isLive={false}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "amatic":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "novomatic":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "bomba":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "egt":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                      case "egtdgtl":
                        return (
                          <BLAS975GameBox
                            key={index}
                            index={index}
                            item={item}
                            provider={provider}
                          />
                        );
                      
                    case "netent":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "yggdrasil":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "playngo":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "merkur":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "aristocrat":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "redtiger":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                    case "playtech":
                      return (
                        <BLAS975GameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                      break;
                    case "iconix":
                      return (
                        <TimelessTechGameBox
                          key={index}
                          index={index}
                          item={item}
                          provider={provider}
                        />
                      );
                      break;

                    default:
                      if (provider.includes("timeless")) {
                        return (
                          <TimelessTechGameBox
                            key={index}
                            index={index}
                            item={item}
                            provider={provider}
                          />
                        );
                      } else if (provider.includes("-fundist")) {
                        return (
                          <WMGameBox
                            key={index}
                            isLive={false}
                            index={index}
                            item={item}
                            provider={provider}
                          />
                        );
                      } else {
                        return (
                          <DetroitBox
                            key={index}
                            index={index}
                            item={item}
                            provider={provider}
                          />
                        );
                      }

                      break;
                  }
                })}
            </div>
          </div>
          {fabActive && (
            <button
              className="btn btn-primary text-white btn-circle fixed right-5 bottom-20 shadow-xl fade-in"
              onClick={() => {
                const scl = containerRef.current;
                scl.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                />
              </svg>
            </button>
          )}

          <div className=" mobile-show">
            <div
              style={{ visibility: isNavVisible ? "visible" : "hidden" }}
              className="btm-nav  border-t border-primary-500 bg-gradient-to-r from-primary-700 via-primary-600 to-primary-700 drop-shadow-2xl shadow-2xl"
            >
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/sports"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_SPORTS")}
                    </span>
                  </div>
                </Link>
              )}
              <Link
                to={"/casino"}
                className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <MdOutlineCasino className="w-5 h-5" />
                  <span className="btm-nav-label text-xs">
                    {t("TITLE_CASINO")}
                  </span>
                </div>
              </Link>
              {props.permissions?.SPORTS_ACCESS?.Value && (
                <Link
                  to={"/my-account/coupons"}
                  className="text-white max-w-[4.4rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                      />
                    </svg>

                    <span className="btm-nav-label text-xs">
                      {t("TITLE_MYBETS")}
                    </span>
                  </div>
                </Link>
              )}

              {props.permissions?.LIVECASINO_ACCESS?.Value && (
                <Link
                  to={"/live-casino"}
                  className="text-white max-w-[5rem]  border-secondary-400 bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
                >
                  <div className="flex flex-col justify-center items-center">
                    <PiCoinsBold className="w-5 h-5" />
                    <span className="btm-nav-label text-xs">
                      {t("TITLE_LIVECASINO")}
                    </span>
                  </div>
                </Link>
              )}

              <Link
                to={"/my-account/profile/"}
                className="text-white max-w-[5rem] bg-transparent border-0 btn btn-md btn-square btn-primary  rounded-none"
              >
                <div className="flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>

                  <span className="btm-nav-label text-xs">
                    {t("TITLE_MYACCOUNT")}
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state, props) => {
  const { authentication, game } = state;
  const { user, loggedIn, permissions } = authentication;
  const { sports, games } = game;
  return {
    user,
    loggedIn,
    sports,
    games,
    permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    UpdateSport: (data) => dispatch(gameAction.UpdateSportsData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScrollableListView);

export const GetGamePoster = (cat, data) => {
  switch (cat) {
    case "pragmatic-casino":
      return `https://common-static.ppgames.net/game_pic/square/200/${data.gameID}.png`;
    case "smartsoft":
      return `https://maxbetone.s3.eu-central-1.amazonaws.com/images/smartsoft/${data.GameName}.png`.toLowerCase();
    default:
      break;
  }
};

export const GameProviders = [
  {
    name: "pragmatic.png",
    value: "pragmatic-casino",
  },
  {
    name: "pragmatic.png",
    value: "pragmatic",
  },
  {
    name: "wm.png",
    value: "WorldMatch-fundist",
  },

  {
    name: "card-02.png",
    value: "amatic",
  },
  {
    name: "card-03.png",
    value: "novomatic",
  },
  {
    name: "card-04.png",
    value: "bomba",
  },
  {
    name: "card-05.png",
    value: "egt",
  },
  {
    name: "egtdigital.png",
    value: "egtdgtl",
  },
  {
    name: "card-06.png",
    value: "netent",
  },
  {
    name: "card-07.png",
    value: "yggdrasil",
  },

  {
    name: "card-09.png",
    value: "playngo",
  },

  {
    name: "card-10.png",
    value: "aristocrat",
  },
  {
    name: "card-11.png",
    value: "redtiger",
  },
  {
    name: "card-12.png",
    value: "playtech",
  },
  {
    name: "1x2.png",
    value: "1X2_GAMING",
  },

  {
    name: "evoplay.png",
    value: "EVOPLAY",
  },

  {
    name: "igt.png",
    value: "IGT",
  },

  {
    name: "playson.png",
    value: "PLAYSON",
  },

  {
    name: "booongo.png",
    value: "BOOONGO",
  },
  {
    name: "wazdan.png",
    value: "WAZDAN",
  },

  {
    name: "rubyplay.png",
    value: "RUBYPLAY",
  },

  {
    name: "microgaming.png",
    value: "MICROGAMING",
  },

  {
    name: "betsoft.png",
    value: "BETSOFT",
  },

  {
    name: "octavian.png",
    value: "OCTAVIAN",
  },
  {
    name: "scientific_games.png",
    value: "SCIENTIFIC_GAMES",
  },
  {
    name: "evoplay.png",
    value: "Evoplay-fundist",
  },

  {
    name: "timeless/SMARSOFT_LOGO_White.png",
    value: "SmartSoft-fundist",
  },

  {
    name: "TomHorn.png",
    value: "Tom Horn-fundist",
  },

  {
    name: "gameart.png",
    value: "GameArt-fundist",
  },

  {
    name: "AG_logo_white_hori.png",
    value: "AsiaGamingSlots-fundist",
  },

  {
    name: "BetSolutions_logo_white.png",
    value: "BetSoft-fundist",
  },
  {
    name: "BGaming_logo_bw.png",
    value: "BGaming-fundist",
  },
  {
    name: "fundist/novomatic-1-line-logo-white.png",
    value: "Novomatic-fundist",
  },
  {
    name: "fundist/Onlyplay_logo.png",
    value: "Onlyplay-fundist",
  },
  {
    name: "fundist/3Oaks_logo_w.png",
    value: "3 Oaks Gaming-fundist",
  },
];

/*
{ name: "timeless/3Oaks_logo_w.png", value: "3oaks-timelesstech" },
  { name: "timeless/3Oaks_logo_white.png", value: "3oaksP-timelesstech" },
  {
    name: "timeless/TurboGames_logo_white.png",
    value: "turbo-games-timelesstech",
  },
  { name: "timeless/Playson_logo-w.png", value: "play-son-timelesstech" },
  { name: "timeless/Playson_logo-white.png", value: "play-sonP-timelesstech" },
  { name: "timeless/7777_logo_w.png", value: "7777-timelesstech" },
  {
    name: "timeless/Iconix_Interactive_logo-01.png",
    value: "iconix-timelesstech",
  },
  { name: "timeless/RocketMan_logo.png", value: "rocketman-timelesstech" },
  { name: "timeless/KAg_logo_w.png", value: "kagaming-timelesstech" },
  { name: "timeless/Netend_logo_white.png", value: "net ent P-timelesstech" },
  { name: "timeless/EvoPlay_logo_w.png", value: "evoplay-timelesstech" },
  { name: "timeless/Habanero_Logo_w.png", value: "habanero-timelesstech" },
  {
    name: "timeless/Galaxsys_logo-blue-RGB-01.png",
    value: "galaxsys-timelesstech",
  },
  {
    name: "timeless/CT_Interactive_logo_green.png",
    value: "ctinteractive-timelesstech",
  },
  { name: "timeless/Mancala_logo_white.png", value: "mancala-timelesstech" },
  { name: "timeless/retrobet.png", value: "retrogames-timelesstech" },
  { name: "timeless/Spinomenal_logo.png", value: "spinomenal-timelesstech" },
  { name: "timeless/Platipus_white.png", value: "platipus-timelesstech" },
  { name: "timeless/FG_bw.png", value: "fils-timelesstech" },
  { name: "timeless/BF_GAMES_LOGO_w.png", value: "bfgames-timelesstech" },
  { name: "timeless/PopOk_Vector_02.png", value: "popok-timelesstech" },
  { name: "timeless/JetX_logo.png", value: "jetx-timelesstech" },
  { name: "timeless/SMARSOFT_LOGO_White.png", value: "smartsoft-timelesstech" },
  { name: "timeless/PGSoft_logo_white.png", value: "pgsoft-timelesstech" },
  {
    name: "timeless/PlatinGaming_white.png",
    value: "platingaming-timelesstech",
  },
  {
    name: "timeless/Endorphina_Logo_White_Text.png",
    value: "endorphina-timelesstech",
  },
  { name: "timeless/SkyWindGroup_Logo2021.png", value: "skywind-timelesstech" },
  { name: "timeless/7mojos_logo.png", value: "7-mojos-slots-timelesstech" },

  { name: "timeless/EAGaming_logo.png", value: "eagaming-timelesstech" },
  { name: "timeless/big_time.svg", value: "bigtimegaming-timelesstech" },
  { name: "timeless/netgame.png", value: "netgame-timelesstech" },
  { name: "timeless/Aviator_logo_1.png", value: "aviator-timelesstech" },
  { name: "timeless/Spribe.png", value: "spribe-timelesstech" },
  { name: "timeless/RedTiger_Red2.png", value: "red tiger P-timelesstech" },
  { name: "timeless/RedTiger_White2.png", value: "red tiger-timelesstech" },
  { name: "timeless/Netend_logo_white.png", value: "net ent-timelesstech" },
  { name: "timeless/NolimitCity_white.png", value: "nolimitcity-timelesstech" },
  {
    name: "timeless/MacawGamingTextLogoWhite.png",
    value: "macaw-timelesstech",
  },
  { name: "timeless/Booming.png", value: "booming-timelesstech" },

*/

/*
  { name: "timeless/PP_Logo_On.png", value: "pragmatic-timelesstech" },
  { name: "timeless/PP_Logo_On.png", value: "pragmaticS-timelesstech" },

*/

/**
 
VİRTUAL E GEÇTİ TAMAMEN

 {
    name: "virtual.png",
    value: "VIRTUAL",
  },
    {
    name: "racing.png",
    value: "RACING",
  },

 */

/*
KALDIRILAN SAĞLAYICILAR

{
    {
    name: "merkur.svg",
    value: "merkur",
  },
   {
    name: "booming_games.png",
    value: "BOOMING_GAMES",
  },

    {
    name: "big_time_gaming.png",
    value: "BIG_TIME_GAMING",
  },
    {
    name: "leap.png",
    value: "LEAP",
  },
    name: "gaminator.png",
    value: "GAMINATOR",
  },
   {
    name: "platipus.png",
    value: "PLATIPUS",
  },
   {
    name: "merkur.png",
    value: "MERKUR",
  },
  {
    name: "red_rake.png",
    value: "RED_RAKE",
  },
  {
    name: "apex.png",
    value: "APEX",
  }
   {
    name: "quickspin.png",
    value: "QUICKSPIN",
  }, 
 
  {
    name: "ainsworth.png",
    value: "AINSWORTH",
  },
  {
    name: "firekirin.png",
    value: "FIREKIRIN",
  },
  {
    name: "apollo_games.png",
    value: "APOLLO_GAMES",
  },
  {
    name: "kajot.png",
    value: "KAJOT",
  },
  {
    name: "gameart.png",
    value: "GAMEART",
  },{
    name: "habanero.png",
    value: "HABANERO",
  },  {
    name: "isoftbet.png",
    value: "ISOFTBET",
  }, 
   {
    name: "skywind.png",
    value: "SKYWIND",
  },
    
   {
    name: "spinomenal.png",
    value: "SPINOMENAL",
  },
 {
    name: "iron_dog.png",
    value: "IRON_DOG",
  },
 {
    name: "igrosoft.png",
    value: "IGROSOFT",
  },*/

/*  {
    name: "xplosive.png",
    value: "XPLOSIVE",
  },
{
    name: "zeus_play.png",
    value: "ZEUS_PLAY",
  }
  */

/*
GAMES TARAFINA TRANSFER EDİLDİ

 {
    name: "super_keno.png",
    value: "SUPER_KENO",
  },
  {
    name: "spribe.png",
    value: "SPRIBE",
  },

*/
