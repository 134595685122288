import React from "react";
import { connect } from "react-redux";
import { GetGamePoster } from "../ScrollableListView";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { IoPlayCircleOutline } from "react-icons/io5";
import { Translation } from "react-i18next";

const SmartSoftGameBox = (props) => {
  const { item, index } = props;
  return (
    <Translation>
      {(t) => (
        <>
          <div
            key={item._id}
            className="GameBox h-[136px] md:h-[220px]  bg-primary-500 md:bg-secondary-500 group/item cursor-pointer md:border md:border-primary-500"
          >
            <div className="games-img">
              <AsyncImage
                className="h-full w-full rounded-t-[2.5vw] md:rounded-t-md"
                src={GetGamePoster("smartsoft", item)}
                loader={
                  <div className="skeleton w-full h-full bg-secondary-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                error={
                  <div className="bg-light-500">
                    <img
                      src={require("../../../assets/img/logotexttans.png")}
                    ></img>
                  </div>
                }
                alt={`
        ${item.Name}
        
        [${item.GameName}]`}
              />
            </div>

            <div className="game-hover group-hover/item:before:opacity-80"></div>
            <div className="game-btns   flex-col  ">
              <Link
                to={`/smartsoft/${item._id}`}
                className="flex flex-row m-1 btn-block justify-start items-center"
              >
                <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                {t("BUTTON_PLAY")}
              </Link>
              <Link
                to={`/smartsoft-demo/${item._id}`}
                className="flex flex-row m-1 btn-block justify-start items-center"
              >
                <IoPlayCircleOutline className="text-primary-500   w-8 h-8    md:w-10 md:h-10 mr-[4px]" />
                {t("BUTTON_DEMOPLAY")}
              </Link>
            </div>
            <span className="name text-white after:bg-secondary-500 group-hover/item:after:bg-primary-500 group-hover/item:after:opacity-[0.9] group-hover/item:text-secondary-500">
              {item.Name}
            </span>
          </div>
        </>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SmartSoftGameBox);
